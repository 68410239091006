import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetListPharmacyResponse } from '@core/interfaces/pharmacy.interface';
import { LocationFilter } from '@core/interfaces/time-window.interface';
import { OffsetPagination } from '@core/interfaces/zone.interface';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PharmacyService {
  constructor(private apollo: Apollo) {}

  public getListPharmacy(
    filter: LocationFilter,
    pagination: OffsetPagination = { limit: 20, offset: 0 },
  ): Observable<ApolloQueryResult<GetListPharmacyResponse>> {
    return this.apollo.query<GetListPharmacyResponse>({
      query: gql`
        query GetListPharmacy($filter: PharmacyFilterDto, $pagination: OffsetPaginationInput) {
          getListPharmacy(filter: $filter, pagination: $pagination) {
            result {
              geoDataDistrictId
              geoDataLocalityId
              geoDataRegionId
              id
              isBase
              pharmacyAddress
              pharmacyBrandId
              pharmacyEmail
              pharmacyIsClosed
              pharmacyIsExternal
              pharmacyLegalName
              pharmacyLocalizedAddress
              pharmacyName
              pharmacyTimeToWork
              pharmacyWorkingNow
              zoneId
              geoPoint
            }
            total
          }
        }
      `,
      variables: {
        filter,
        pagination,
      },
    });
  }
}
